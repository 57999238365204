import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { Close } from '@material-ui/icons'
import { useMutation } from 'react-query'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { BusinessContext } from 'src/context/BusinessContext'
import { requestNewNotAvailableDocument } from 'src/api/operations/post/businessUpload'
import { DialogTitleStyled } from '../Dialogs/style'

interface INotAvailableDocument {
  documentId: number
  documentName: string
  close: () => void
  open: boolean
  type: string
}
const NotAvailableDocument: React.FC<INotAvailableDocument> = ({
  documentId,
  documentName,
  close,
  open,
  type,
}) => {
  const [reason, setReason] = useState('')
  const businessContext = useContext<any>(BusinessContext)
  const { enqueueSnackbar } = useSnackbar()
  const mutationRequest = useMutation({
    mutationFn: async () => {
      await requestNewNotAvailableDocument(
        documentId,
        documentName,
        businessContext.id,
        businessContext.name,
        reason,
        type
      ).then(() => {})
      enqueueSnackbar('Request created.', notistackOptions('success'))
      onCloseDialog()
    },
  })

  const handleSubmit = () => {
    mutationRequest.mutateAsync()
  }

  const onCloseDialog = () => {
    setReason('')
    close()
  }

  return (
    <>
      <Dialog
        PaperProps={{
          style: { width: 450 },
        }}
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="md"
      >
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Reason
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <TextField
            multiline={true}
            minRows={5}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 400 }}
            name="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Reason"
            // disabled={mutationRejectFile.isLoading}
          />
          <Typography
            style={{
              color: 'silver',
              textAlign: 'right',
            }}
          >
            {`${reason?.length ?? 0}/400`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setReason('')
              close()
            }}
            disabled={mutationRequest.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!reason || mutationRequest.isLoading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default NotAvailableDocument
