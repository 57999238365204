import { httpClient } from 'src/api/axios/api'
import { INotAvailableDocumentsResponseData } from 'src/api/models'
import {
  getBusinessNotAvailableDocuments,
  getNotAvailableDocument,
} from './urls'

export const GetNotAvailableDocument = async (
  businessId: string | undefined,
  type: string,
  document: number
): Promise<any> => {
  const response = await httpClient.get(getNotAvailableDocument, {
    params: {
      businessId,
      document,
      type,
    },
  })
  return response?.data
}

export const GetBusinessNotAvailableDocuments = async (
  businessId: string,
  active: boolean,
  pagination: string | null
): Promise<INotAvailableDocumentsResponseData> => {
  const response = await httpClient.get(getBusinessNotAvailableDocuments, {
    params: {
      businessId,
      active,
      pagination,
    },
  })
  return response?.data
}
