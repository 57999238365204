import {
  Dialog,
  Box,
  DialogContent,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { Cancel, Check, Close } from '@material-ui/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { GridColumn as KendoGridColumn } from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import { answerRequestNotAvailableDocument } from 'src/api/operations/post/businessUpload'
import { State } from '@progress/kendo-data-query'
import { renderDate } from 'src/utils/formatKendoColumns'
import { GetBusinessNotAvailableDocuments } from 'src/api/operations/get/businessUpload'
import { DialogTitleStyled } from '../Dialogs/style'
import Loader from '../Loader'

interface INotAvailableDocuments {
  close: () => void
  open: boolean
  businessId: string
}
const NotAvailableDocuments: React.FC<INotAvailableDocuments> = ({
  close,
  open,
  businessId,
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const grid = useRef<any>(null)
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 10,
    filter: undefined,
    sort: undefined,
  })

  const getBusinessNotAvailableDocument = useQuery({
    cacheTime: 0,
    queryKey: ['getBusinessNotAvailableDocument', businessId, gridState],
    queryFn: async () => {
      const result = await GetBusinessNotAvailableDocuments(
        businessId,
        false,
        encodeURIComponent(JSON.stringify(gridState))
      )
      return result
    },
  })
  const notAvailableDoc = getBusinessNotAvailableDocument?.data

  const mutationRequest = useMutation({
    mutationFn: async (requestOption: any) => {
      await answerRequestNotAvailableDocument(
        requestOption.id,
        requestOption.option
      ).then(() => {})
      enqueueSnackbar('Request created.', notistackOptions('success'))
      queryClient.refetchQueries({
        queryKey: 'getBusinessNotAvailableDocument',
      })
    },
  })

  const handleSubmit = (id: number, option: boolean) => {
    const answer = {
      id,
      option,
    }

    mutationRequest.mutateAsync(answer)
  }

  const onCloseDialog = () => {
    close()
  }

  const columns: any[] = [
    { field: 'id', title: 'Id', show: false },
    {
      field: 'documentName',
      title: 'Document',
      show: true,
    },
    { field: 'reason', title: 'Reason', show: true },
    {
      field: 'requestDateUtc',
      title: 'Request Date',
      show: true,
      type: 'date',
      cell: renderDate,
      width: '120px',
    },
    {
      field: 'acceptedDateUtc',
      title: 'Answered Date',
      show: false,
      type: 'date',
      cell: renderDate,
      width: '120px',
    },
    {
      field: 'id',
      title: ' ',
      show: true,

      width: '80px',
      cell: (props: any) => {
        return (
          <>
            <td className="k-command-cell">
              <Box
                display="flex"
                flexDirection="row"
                justifyItems="space-between"
              >
                <Tooltip title="Accept">
                  <span>
                    <IconButton
                      disabled={props.dataItem['active']}
                      color="primary"
                      aria-label="Accept"
                      onClick={(e: any) => {
                        handleSubmit(props.dataItem['id'], true)
                      }}
                    >
                      <Check fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Reject">
                  <span>
                    <IconButton
                      disabled={
                        props.dataItem['active'] !== null &&
                        props.dataItem['active'] === false
                      }
                      color="primary"
                      aria-label="Reject"
                      onClick={(e: any) => {
                        handleSubmit(props.dataItem['id'], false)
                      }}
                    >
                      <Cancel fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </td>
          </>
        )
      },
    },
  ]

  return (
    <>
      <Dialog
        PaperProps={{
          style: { width: '1000px', height: '600px' },
        }}
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="xl"
      >
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Reason
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Box>
            {getBusinessNotAvailableDocument.isLoading && <Loader />}
            <KendoGrid
              ref={grid}
              scrollable="scrollable"
              data={notAvailableDoc?.notAvailableDocumentsResponses ?? []}
              skip={gridState.skip}
              take={gridState.take}
              pageSize={gridState.take}
              filter={gridState.filter}
              sort={gridState.sort}
              sortable
              pageable={{ pageSizes: [1, 2, 25, 50, 100] }}
              total={notAvailableDoc?.total ?? 0}
              onDataStateChange={(e) => {
                setGridState(e.dataState)
              }}
              style={{ height: '100%' }}
            >
              {columns.reduce((acc, item, idx) => {
                const component = [...acc]

                if (item.show) {
                  component.push(
                    <KendoGridColumn
                      key={item.field + item.title}
                      field={item.field}
                      title={item.title}
                      cell={item.cell}
                      width={item.width}
                    />
                  )
                }
                return component
              }, [])}
            </KendoGrid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default NotAvailableDocuments
