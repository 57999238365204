import { httpClient } from 'src/api/axios/api'
import { answerNotAvailableDocument, requestNotAvailableDocument } from './urls'

export const requestNewNotAvailableDocument = async (
  documentId: number,
  documentName: string,
  businessId: string,
  businessName: string,
  reason: string,
  type: string
): Promise<any> => {
  const response = await httpClient.post(requestNotAvailableDocument, {
    documentId,
    documentName,
    businessId,
    businessName,
    reason,
    type,
  })
  return response?.data
}

export const answerRequestNotAvailableDocument = async (
  requestId: number,
  accept: false
): Promise<any> => {
  const response = await httpClient.post(answerNotAvailableDocument, {
    requestId,
    accept,
  })
  return response?.data
}
